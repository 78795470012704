<template lang="html">
  <b-card class="sheet-card max-w-lg">
    <div class="jaya-top-row">
      <viewer :images="getPhoto(editingSheet)" class="jaya-container">
        <img v-for="src in getPhoto(editingSheet)" :key="src" :alt="'Fiche pratique ' + editingSheet.title" :src="src"
            class="jaya-image">
      </viewer>

      <div v-if="!editable && !editingSheet.newSheet" class="jaya-image-buttons">
        <b-button
            class="p-50"
            pill
            size="sm"
            variant="primary"
            @click="$copyLink('fiches-pratiques', editingSheet.id)">
          <feather-icon
              icon="Link2Icon"
              size="20"/>
        </b-button>
      </div>

      <div v-if="editable" class="jaya-image-edit-buttons">
        <b-button
            class="p-50"
            pill
            size="sm"
            variant="flat-primary"
            @click="addPhotoPrompt = true">
          <feather-icon
              :icon="editingSheet.photos && editingSheet.photos.length > 0 ? 'Edit2Icon' : 'PlusIcon'"
              size="16"/>
        </b-button>
        <b-button
            v-if="editingSheet.photos && editingSheet.photos.length"
            class="p-50"
            pill
            size="sm"
            variant="delete"
            @click="deletePhotoPrompt = true">
          <feather-icon
              icon="TrashIcon"
              size="16"/>
        </b-button>
      </div>

      <div style="position:absolute;top:0;left:0;">
        <b-button
            variant="secondary"
            @click="changeEditable">
          <feather-icon
              :icon="editable ? 'EyeIcon' : 'Edit2Icon'"
              size="16"/>
          <span class="align-middle ml-50">
          {{ editable ? 'Visualiser' : 'Éditer' }}
          </span>
        </b-button>
      </div>
    </div>

    <b-alert v-if="editable && isModified" class="text-center mb-1" variant="danger">
      Modifications non enregistrées
    </b-alert>

    <h2 class="d-flex justify-content-center mt-1">
      <span>{{ editingSheet.title }}</span>
      <b-button
          v-if="editable"
          class="p-50"
          pill
          size="sm"
          variant="flat-primary"
          @click="showEditTitlePrompt">
        <feather-icon
            icon="Edit2Icon"
            size="16"/>
      </b-button>
    </h2>

    <div class="d-flex align-items-center">
      <div class="text-justify font-medium-2 mb-1 font-weight-bold">
        <p v-for="(part,id) in sheet.introduction.split('\n')" :key="id" class="mt-1">{{part}}</p>
      </div>

      <b-button
          v-if="editable"
          class="p-50"
          pill
          size="sm"
          variant="flat-primary"
          @click="showEditIntroPrompt">
        <feather-icon
            icon="Edit2Icon"
            size="16"/>
      </b-button>
    </div>

    <TextBlock
        v-for="paragraph in editingSheet.paragraphs"
        :key="paragraph.order"
        activeColor="var(--secondary)">
      <div slot="title" class="d-flex align-items-center mt-1">
        {{ paragraph.title }}
        <b-button
            v-if="editable"
            class="p-50"
            pill
            size="sm"
            variant="flat-primary"
            @click="showEditParagraphTitle(paragraph.order)">
          <feather-icon
              icon="Edit2Icon"
              size="16"/>
        </b-button>
        <b-button
            v-if="editable"
            class="p-50"
            pill
            size="sm"
            variant="flat-danger"
            @click="showDeleteParagraphPrompt(paragraph.order)">
          <feather-icon
              icon="TrashIcon"
              size="16"/>
        </b-button>
      </div>

      <div class="text-justify">
        <span v-for="(item, index) in paragraph.content" :key="index" :class="item.type==='list' ? 'w-full' : ''">
          <span v-if="item.type==='text'" :class="editable ? 'd-flex align-items-center' : ''" class="rounded-lg pr-25"
              @click="showEditTextPrompt(paragraph.order, item.order)">
            <span>
              <span v-for="(part,id) in parseTextParagraph(item.content.text)" :key="id">
                {{part[0]}}
                <br v-if="id>0 && id<part[1]-1">
                <br v-if="id>0 && id<part[1]-1">
              </span>
            </span>

            <b-button
                v-if="editable"
                class="p-50"
                pill
                size="sm"
                variant="flat-primary"
                @click="showEditTextPrompt(paragraph.order, item.order)">
              <feather-icon
                  icon="Edit2Icon"
                  size="16"/>
            </b-button>
          </span>
          <span v-if="item.type==='tip'" :class="editable ? 'd-flex align-items-center' : ''">
            <JayaTooltip
                :text="item.content.description">
              {{ item.content.expression }}
            </JayaTooltip>
            <b-button
                v-if="editable"
                class="p-50"
                pill
                size="sm"
                variant="flat-primary"
                @click="showEditToolTipPrompt(paragraph.order, item.order)">
              <feather-icon
                  icon="Edit2Icon"
                  size="16"/>
            </b-button>
          </span>
          <span v-if="item.type==='list'" class="w-100">
            <b-list-group
                v-if="editingList"
                class="w-100 rounded-lg"
                flush
                @click="showEditListPrompt(paragraph.order, item.order)">
              <b-list-group-item
                  v-for="listItem in orderByOrder(item.content.items)"
                  :key="listItem.order">
                 {{ listItem.text }}
              </b-list-group-item>
            </b-list-group>

            <b-button
                v-if="editable"
                class="p-50"
                size="sm"
                variant="flat-primary"
                @click="showEditListPrompt(paragraph.order, item.order)">
              <feather-icon
                  icon="Edit2Icon"
                  size="16"/>
              <span class="align-middle pl-50">Modifier la liste</span>
            </b-button>
          </span>
          <span v-if="item.type==='link'" :class="editable ? 'd-flex align-items-center' : ''">
            <span class="text-primary cursor-pointer" @click="openLink(item.content.url)">
              {{ item.content.expression }}
            </span>

            <b-button
                v-if="editable"
                class="p-50"
                pill
                size="sm"
                variant="flat-primary"
                @click="showEditLinkPrompt(paragraph.order, item.order)">
              <feather-icon
                  icon="Edit2Icon"
                  size="16"/>
            </b-button>
          </span>
        </span>
      </div>

      <div v-if="editable" class="d-flex align-items-center justify-items-center flex-wrap mt-1">
        <b-button
            class="mt-50 mr-50"
            variant="outline-primary"
            @click="addText(paragraph.order)">
          <feather-icon
              icon="PlusIcon"
              size="16"/>
          <span class="align-middle pl-50">Texte</span>
        </b-button>
        <b-button
            class="mt-50 mr-50"
            variant="outline-primary"
            @click="addToolTip(paragraph.order)">
          <feather-icon
              icon="PlusIcon"
              size="16"/>
          <span class="align-middle pl-50">Info-bulle</span>
        </b-button>
        <b-button
            class="mt-50 mr-50"
            variant="outline-primary"
            @click="addList(paragraph.order)">
          <feather-icon
              icon="PlusIcon"
              size="16"/>
          <span class="align-middle pl-50">Liste</span>
        </b-button>
        <b-button
            class="mt-50"
            variant="outline-primary"
            @click="addLink(paragraph.order)">
          <feather-icon
              icon="PlusIcon"
              size="16"/>
          <span class="align-middle pl-50">Lien</span>
        </b-button>
      </div>
    </TextBlock>

    <TextBlock v-if="editable" activeColor="var(--secondary)">
      <div slot="title" class="d-flex align-items-center mt-1">
        Ajouter un paragraphe
        <b-button
            variant="flat-secondary"
            @click="addParagraph">
          <feather-icon
              icon="PlusIcon"
              size="20"/>
        </b-button>
      </div>
    </TextBlock>

    <div v-if="editable" class="d-flex justify-content-around">
      <b-button
          v-if="isModified"
          variant="danger"
          @click="cancelModificationsPrompt = true">
        <span class="align-middle">Annuler les modifications</span>
      </b-button>
      <b-button
          v-if="isModified"
          @click="updateSheetPrompt = true">
        <span class="align-middle">{{ editingSheet.newSheet ? 'Publier cette fiche' : 'Enregistrer et publier' }}</span>
      </b-button>
      <b-button
          v-if="!isModified"
          variant="danger"
          @click="deleteSheetPrompt = true">
        <feather-icon
            icon="TrashIcon"
            size="16"/>
        <span class="align-middle ml-50">Supprimer cette fiche</span>
      </b-button>
    </div>

    <div v-if="editingSheet.local_authority_infos && !editable"
        class="d-flex justify-content-start align-items-center mt-3">
      <img
          v-if="editingSheet.local_authority_infos.photo_url"
          :alt="editingSheet.local_authority_infos.name + ' logo'"
          :src="getImage(editingSheet.local_authority_infos.photo_url)"
          class="mr-1"
          style="width: 30px;">
      <span>Fiche proposée par<strong> {{ sheet.local_authority_infos.name }} </strong></span>
    </div>

    <JayUpload :active.sync="addPhotoPrompt"
        :title="editingSheet.photos && editingSheet.photos.length > 0 ? 'Modifier la photo' : 'Ajouter une photo'"
        @on-success="addPhoto"/>

    <b-modal
        v-model="deletePhotoPrompt"
        cancel-title="Annuler"
        ok-title="Supprimer"
        title="Suppression"
        variant="danger"
        centered
        @ok="deletePhoto">
      <p>Êtes-vous sûr de vouloir supprimer la photo ?</p>
    </b-modal>

    <validation-observer v-slot="{invalid}">
      <b-modal
          ref="editTitlePrompt"
          v-model="editTitlePrompt"
          :ok-disabled="invalid"
          cancel-title="Annuler"
          ok-title="Valider"
          title="Modifier le titre de la fiche pratique"
          centered
          @ok="updateTitle">
        <b-form-group label="Titre de la fiche :">
          <validation-provider v-slot="{errors}" name="Titre" rules="required|max:128">
            <b-form-input
                v-model="editingTitle"
                placeholder="Titre"/>
            <b-form-text class="text-danger">
              {{ errors[0] }}
            </b-form-text>
          </validation-provider>
        </b-form-group>
      </b-modal>
    </validation-observer>

    <validation-observer v-slot="{invalid}">
      <b-modal
          v-model="editIntroPrompt"
          :ok-disabled="invalid"
          cancel-title="Annuler"
          ok-title="Valider"
          title="Modifier l'introduction de la fiche pratique"
          centered
          @ok="updateIntro">
        <b-form-group label="Introduction de la fiche :">
          <validation-provider v-slot="{errors}" name="Introduction" rules="required|max:5096">
            <b-form-textarea
                v-model="editingIntro"
                placeholder="Introduction"/>
            <b-form-text class="text-danger">
              {{ errors[0] }}
            </b-form-text>
          </validation-provider>
        </b-form-group>
      </b-modal>
    </validation-observer>

    <validation-observer v-slot="{invalid}">
      <b-modal
          v-model="editParagraphTitlePrompt"
          :ok-disabled="invalid"
          cancel-title="Annuler"
          ok-title="Valider"
          title="Modifier le titre du paragraphe"
          centered
          @ok="updateParagraphTitle">
        <b-form-group label="Titre du paragraphe :">
          <validation-provider v-slot="{errors}" name="Titre du paragraphe" rules="required|max:128">
            <b-form-input
                v-model="editingParagraphTitle"
                placeholder="Titre du paragraphe"/>
            <b-form-text class="text-danger">
              {{ errors[0] }}
            </b-form-text>
          </validation-provider>
        </b-form-group>
      </b-modal>
    </validation-observer>

    <b-modal
        v-model="deleteParagraphPrompt"
        cancel-title="Annuler"
        ok-title="Supprimer"
        title="Supprimer le paragraphe"
        variant="danger"
        centered
        @ok="deleteParagraph">
      <p> Êtes vous certain de supprimer ce paragraphe et tout ce qu'il contient ? </p>
    </b-modal>

    <validation-observer v-slot="{invalid}">
      <b-modal
          v-model="editActiveTextPrompt"
          :active.sync="editActiveTextPrompt"
          :ok-disabled="invalid"
          cancel-title="Annuler"
          ok-title="Valider"
          title="Modifier le texte de paragraphe"
          centered
          @ok="updateActiveText">
        <b-form-group label="Texte du paragraphe :">
          <validation-provider v-slot="{errors}" name="Text du paragraphe" rules="required|max:65536">
            <b-form-textarea
                v-model="editingActiveText"
                placeholder="Texte du paragraphe"/>
            <b-form-text class="text-danger">
              {{ errors[0] }}
            </b-form-text>
          </validation-provider>
        </b-form-group>

        <b-button
            class="mt-1"
            variant="outline-danger"
            @click="showDeleteActiveContentPrompt">
          <feather-icon
              icon="TrashIcon"
              size="16"/>
          <span class="align-middle pl-50">Supprimer le paragraphe</span>
        </b-button>
      </b-modal>
    </validation-observer>

    <validation-observer v-slot="{invalid}">
      <b-modal
          v-model="editActiveToolTipPrompt"
          :ok-disabled="invalid"
          cancel-title="Annuler"
          ok-title="Valider"
          title="Modifier l'info-bulle"
          centered
          @ok="updateActiveToolTip">
        <b-form-group label="Expression à décrire :">
          <validation-provider v-slot="{errors}" name="Expression à décrire" rules="required|max:64">
            <b-form-input
                v-model="editingActiveToolTip.expression"
                placeholder="Expression à décrire"/>
            <b-form-text class="text-danger">
              {{ errors[0] }}
            </b-form-text>
          </validation-provider>
        </b-form-group>

        <b-form-group label="Description associée :">
          <validation-provider v-slot="{errors}" name="Description associée" rules="required|max:5096">
            <b-form-textarea
                v-model="editingActiveToolTip.description"
                placeholder="Description associée"/>
            <b-form-text class="text-danger">
              {{ errors[0] }}
            </b-form-text>
          </validation-provider>
        </b-form-group>

        <b-button
            variant="outline-danger"
            @click="showDeleteActiveContentPrompt">
          <feather-icon
              icon="TrashIcon"
              size="16"/>
          <span class="align-middle ml-50">Supprimer l'info-bulle</span>
        </b-button>
      </b-modal>
    </validation-observer>

    <validation-observer v-slot="{invalid}">
      <b-modal
          v-model="editActiveLinkPrompt"
          :ok-disabled="invalid"
          cancel-title="Annuler"
          ok-title="Valider"
          title="Modifier le lien"
          centered
          @ok="updateActiveToolLink">
        <b-form-group label="Mot ou expression à afficher :">
          <validation-provider v-slot="{errors}" name="Mot ou expresion à afficher" rules="required|max:128">
            <b-form-input
                v-model="editingActiveLink.expression"
                placeholder="Mot ou expresion à afficher"/>
            <b-form-text class="text-danger">
              {{ errors[0] }}
            </b-form-text>
          </validation-provider>
        </b-form-group>

        <b-form-group label="URL associée :">
          <validation-provider v-slot="{errors}" name="URL associée" rules="required|max:4096">
            <b-form-textarea
                v-model="editingActiveLink.url"
                placeholder="URL associée"/>
            <b-form-text class="text-danger">
              {{ errors[0] }}
            </b-form-text>
          </validation-provider>
        </b-form-group>

        <b-button
            class="mt-1"
            variant="outline-danger"
            @click="showDeleteActiveContentPrompt">
          <feather-icon
              icon="TrashIcon"
              size="16"/>
          <span class="align-middle ml-50">Supprimer le lien</span>
        </b-button>
      </b-modal>
    </validation-observer>

    <validation-observer v-slot="{invalid}">
      <b-modal
          v-model="editActiveListPrompt"
          :active.sync="editActiveListPrompt"
          :ok-disabled="invalid"
          cancel-title="Annuler"
          ok-title="Valider"
          title="Modifier la liste"
          centered
          @ok="updateActiveList">
        <div v-if="editingActiveList.length === 0" class="mb-50"> Aucun élément dans la liste</div>
        <div v-for="(item, index) of editingActiveList" v-else :key="item.order" class="d-flex mb-50 align-items-start">
          <validation-provider v-slot="{errors}" class="w-100" name="Element de la liste" rules="required|max:5096">
            <b-form-textarea
                v-model="editingActiveList[index].text"
                placeholder="Element de la liste"/>
            <b-form-text class="text-danger">
              {{ errors[0] }}
            </b-form-text>
          </validation-provider>

          <b-button
              class="p-50"
              pill
              size="sm"
              variant="flat-danger"
              @click="deleteActiveListItem(item.order)">
            <feather-icon
                icon="TrashIcon"
                size="16"/>
          </b-button>
        </div>
        <b-button
            class="mr-50 mt-1"
            variant="outline-primary"
            @click="addListItem">
          <feather-icon
              icon="PlusIcon"
              size="16"/>
          <span class="align-middle pl-50">Ajouter un élément</span>
        </b-button>
        <b-button
            class="mt-1"
            variant="outline-danger"
            @click="showDeleteActiveContentPrompt">
          <feather-icon
              icon="TrashIcon"
              size="16"/>
          <span class="align-middle pl-50">Supprimer la liste</span>
        </b-button>
      </b-modal>
    </validation-observer>

    <b-modal
        v-model="deleteActiveContentPrompt"
        cancel-title="Annuler"
        ok-title="Supprimer"
        title="Supprimer"
        variant="danger"
        centered
        @ok="deleteActiveContent">
      <p> Êtes vous certain de supprimer ce contenu ? </p>
    </b-modal>

    <b-modal
        v-model="updateSheetPrompt"
        cancel-title="Continuer à modifier"
        ok-title="Publier"
        title="Publier"
        variant="primary"
        centered
        @ok="updateHelpSheet">
      <p> Enregistrer et publier les modifications ? </p>
    </b-modal>

    <b-modal
        v-model="cancelModificationsPrompt"
        cancel-title="Continuer à modifier"
        ok-title="Supprimer les modifications"
        title="Ne pas enregistrer"
        variant="danger"
        centered
        @ok="cancelModifications">
      <p> Êtes vous certain d'annuler <strong>toutes</strong> les modifications apportées aux fiches ? Elles ne seront
        pas enregistrées. </p>
    </b-modal>

    <b-modal
        v-model="deleteSheetPrompt"
        cancel-title="Annuler"
        ok-title="Supprimer"
        title="Supprimer la fiche"
        variant="danger"
        centered
        @ok="deleteSheet">
      <p> Êtes vous certain de supprimer cette fiche ? Elle ne sera pas récupérable. </p>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BButton,
  BCard,
  BFormGroup,
  BFormInput,
  BFormText,
  BFormTextarea,
  BListGroup,
  BListGroupItem,
  BAlert,
} from "bootstrap-vue";
import TextBlock from '@/components/global/JayaTextBlock.vue'
import _ from 'lodash'
import JayUpload from "@/components/global/JayUpload";
import {extend, ValidationObserver, ValidationProvider} from 'vee-validate'
import {max, required} from "vee-validate/dist/rules";

extend('required', {...required, message: 'Ce champ est requis'});
extend('max', {...max, message: 'Le champ "{_field_}" doit faire moins de {length} caractères'});

export default {
  name: 'sheet-card',
  components: {
    BButton,
    BListGroup,
    BListGroupItem,
    BCard,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BFormText,
    BAlert,
    TextBlock,
    JayUpload,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    sheet: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      editingSheet: {},
      editable: true,
      sheetModified: false,
      sheetParagraphs: [],

      editingPhoto: null,
      editingTitle: '',
      editingIntro: '',
      activeParagraphOrder: null,
      activeContentOrder: null,
      editingParagraphTitle: '',
      editingActiveText: '',
      editingActiveToolTip: {description: '', expression: ''},
      editingActiveLink: {url: '', expression: ''},
      editingActiveList: [],
      editingList: true,

      updateSheetPrompt: false,
      cancelModificationsPrompt: false,
      deleteSheetPrompt: false,

      deletePhotoPrompt: false,
      addPhotoPrompt: false,

      editTitlePrompt: false,
      editIntroPrompt: false,

      editParagraphTitlePrompt: false,
      deleteParagraphPrompt: false,

      editActiveTextPrompt: false,
      editActiveToolTipPrompt: false,
      editActiveLinkPrompt: false,
      editActiveListPrompt: false,
      deleteActiveContentPrompt: false,
      newPhoto: false,
    }
  },
  mounted() {
    this.editingSheet = this.sheet
    for (const paragraph of this.editingSheet.paragraphs) {
      var paragraphItems = {order: paragraph.order, title: paragraph.title, content: []}
      for (const text of paragraph.texts) {
        paragraphItems.content.push({
          type: 'text',
          order: text.order,
          content: text
        })
      }
      for (const list of paragraph.lists) {
        paragraphItems.content.push({
          type: 'list',
          order: list.order,
          content: list
        })
      }
      for (const tip of paragraph.tips) {
        paragraphItems.content.push({
          type: 'tip',
          order: tip.order,
          content: tip
        })
      }
      for (const link of paragraph.links) {
        paragraphItems.content.push({
          type: 'link',
          order: link.order,
          content: link
        })
      }
      paragraphItems.content = _.orderBy(paragraphItems.content, 'order')
      this.sheetParagraphs.push(paragraphItems)
    }
    this.editingSheet.paragraphs = _.orderBy(this.sheetParagraphs, 'order')
    if (this.editingSheet.newSheet) {
      window.addEventListener('beforeunload', this.alertNotSaved)
    }
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.alertNotSaved)
  },
  computed: {
    isModified() {
      return this.sheetModified || this.editingSheet.newSheet
    }
  },
  methods: {
    orderByOrder(list) {
      return _.orderBy(list, 'order')
    },
    changeEditable() {
      this.editable = !this.editable
      this.$emit('reload', this.editingSheet.id)
    },
    modifySheet() {
      this.sheetModified = true
      window.addEventListener('beforeunload', this.alertNotSaved)
      this.$emit('reload', this.editingSheet.id)
    },
    alertNotSaved(e) {
      if (this.editingSheet.newSheet || this.sheetModified) {
        e.preventDefault(); //per the standard
        e.returnValue = '';
      } //required for Chrome
    },
    getImage(photo_url) {
      return process.env.VUE_APP_BACKEND_URL + '/..' + photo_url + "&size=350"
    },
    getPhoto() {
      if (this.editingSheet.photos && this.editingSheet.photos.length > 0) {
        if (this.newPhoto) {
          return [this.editingSheet.photos[0].photo_url + "&size=330"]
        } else {
          return [process.env.VUE_APP_BACKEND_URL + '/..' + this.editingSheet.photos[0].photo_url + "&size=330"]
        }
      }
      return [require('@/assets/images/default_pictures/help_sheet_default.svg')]
    },
    addPhoto(photo) {
      this.editingSheet.photos = [{image: photo.id, photo_url: photo.url}]
      this.newPhoto = true
      this.modifySheet()
    },
    deletePhoto() {
      this.editingSheet.photos = []
      this.modifySheet()
    },

    showEditTitlePrompt() {
      this.editingTitle = this.editingSheet.title
      this.editTitlePrompt = true
    },
    updateTitle() {
      // todo: set v-validate
      // this.$validator.pause()
      this.editingSheet.title = this.editingTitle
      this.editingTitle = ''
      this.modifySheet()
    },
    showEditIntroPrompt() {
      this.editingIntro = this.editingSheet.introduction
      this.editIntroPrompt = true
    },
    updateIntro() {
      // this.$validator.pause()
      this.editingSheet.introduction = this.editingIntro
      this.editingIntro = ''
      this.modifySheet()
    },

    addParagraph() {
      //find highest order
      let highestOrder = 0
      for (const paragraph of this.editingSheet.paragraphs) {
        if (paragraph.order > highestOrder) {
          highestOrder = paragraph.order
        }
      }
      this.editingSheet.paragraphs.push({
        order: Number(highestOrder + 1),
        title: 'Paragraphe ' + Number(highestOrder + 1),
        content: [] //lists, tips, texts
      })
      this.modifySheet()
    },
    showEditParagraphTitle(paragraphOrder) {
      this.activeParagraphOrder = paragraphOrder
      this.editingParagraphTitle = this.editingSheet.paragraphs.find(paragraph => paragraph.order === paragraphOrder).title
      this.editParagraphTitlePrompt = true
    },
    updateParagraphTitle() {
      // this.$validator.pause()
      this.editingSheet.paragraphs.find(paragraph => paragraph.order === this.activeParagraphOrder).title = this.editingParagraphTitle
      this.editingParagraphTitle = ''
      this.activeParagraphOrder = null
      this.modifySheet()
    },
    showDeleteParagraphPrompt(paragraphOrder) {
      this.activeParagraphOrder = paragraphOrder
      this.deleteParagraphPrompt = true
    },
    deleteParagraph() {
      this.editingSheet.paragraphs = this.editingSheet.paragraphs.filter(paragraph => paragraph.order !== this.activeParagraphOrder)
      this.deleteParagraphPrompt = false
      this.modifySheet()
    },

    addText(paragraphOrder) {
      this.activeParagraphOrder = paragraphOrder
      let content = this.editingSheet.paragraphs.find(paragraph => paragraph.order === paragraphOrder).content
      let newContentOrder = 0
      if (content.length > 0) {
        newContentOrder = Math.max.apply(Math, content.map(function (o) {
          return o.order;
        })) + 1
      }
      content.push({
        order: newContentOrder,
        type: 'text',
        content: {text: 'Nouveau texte'}
      })
      this.modifySheet()
    },
    showEditTextPrompt(paragraphOrder, contentOrder) {
      if (this.editable) {
        this.activeParagraphOrder = paragraphOrder
        this.activeContentOrder = contentOrder
        this.editingActiveText = this.editingSheet.paragraphs.find(
            paragraph => paragraph.order === paragraphOrder
        ).content.find(
            content => content.order === contentOrder
        ).content.text
        this.editActiveTextPrompt = true
      }
    },
    updateActiveText() {
      // this.$validator.pause()
      this.editingSheet.paragraphs.find(
          paragraph => paragraph.order === this.activeParagraphOrder
      ).content.find(
          content => content.order === this.activeContentOrder
      ).content.text = this.editingActiveText
      this.editingActiveText = ''
      this.activeParagraphOrder = null
      this.activeContentOrder = null
      this.modifySheet()
    },

    addToolTip(paragraphOrder) {
      this.activeParagraphOrder = paragraphOrder
      let content = this.editingSheet.paragraphs.find(paragraph => paragraph.order === paragraphOrder).content
      let newContentOrder = 0
      if (content.length > 0) {
        newContentOrder = Math.max.apply(Math, content.map(function (o) {
          return o.order;
        })) + 1
      }
      content.push({
        order: newContentOrder,
        type: 'tip',
        content: {description: 'Definition', expression: 'Nouvelle info-bulle'}
      })
      this.modifySheet()
    },
    showEditToolTipPrompt(paragraphOrder, contentOrder) {
      this.activeParagraphOrder = paragraphOrder
      this.activeContentOrder = contentOrder

      this.editingActiveToolTip.expression = this.editingSheet.paragraphs.find(
          paragraph => paragraph.order === paragraphOrder
      ).content.find(
          content => content.order === contentOrder
      ).content.expression

      this.editingActiveToolTip.description = this.editingSheet.paragraphs.find(
          paragraph => paragraph.order === paragraphOrder
      ).content.find(
          content => content.order === contentOrder
      ).content.description

      this.editActiveToolTipPrompt = true
    },
    updateActiveToolTip() {
      // this.$validator.pause()
      this.editingSheet.paragraphs.find(
          paragraph => paragraph.order === this.activeParagraphOrder
      ).content.find(
          content => content.order === this.activeContentOrder
      ).content.expression = this.editingActiveToolTip.expression

      this.editingSheet.paragraphs.find(
          paragraph => paragraph.order === this.activeParagraphOrder
      ).content.find(
          content => content.order === this.activeContentOrder
      ).content.description = this.editingActiveToolTip.description

      this.editingActiveToolTip = {description: '', expression: ''}

      this.modifySheet()
    },

    addList(paragraphOrder) {
      this.activeParagraphOrder = paragraphOrder
      let content = this.editingSheet.paragraphs.find(paragraph => paragraph.order === paragraphOrder).content
      let newContentOrder = 0
      if (content.length > 0) {
        newContentOrder = Math.max.apply(Math, content.map(function (o) {
          return o.order;
        })) + 1
      }
      content.push({
        order: newContentOrder,
        type: 'list',
        content: {items: []}
      })
      this.modifySheet()
    },
    showEditListPrompt(paragraphOrder, contentOrder) {
      if (this.editable) {
        this.activeParagraphOrder = paragraphOrder
        this.activeContentOrder = contentOrder
        this.editingActiveList = JSON.parse(JSON.stringify(this.editingSheet.paragraphs.find(
            paragraph => paragraph.order === this.activeParagraphOrder
        ).content.find(content => content.order === this.activeContentOrder).content.items))
        this.editActiveListPrompt = true
      }
    },
    addListItem() {
      let newListItemOrder = 0
      if (this.editingActiveList.length > 0) {
        newListItemOrder = Math.max.apply(Math, this.editingActiveList.map(function (o) {
          return o.order;
        })) + 1
      }
      this.editingActiveList.push({
        order: newListItemOrder,
        text: 'élément ' + Number(newListItemOrder + 1)
      })
      this.modifySheet()
    },
    deleteActiveListItem(itemOrder) {
      this.editingList = false
      let elIndex = this.editingActiveList.findIndex(item => item.order === itemOrder)
      this.editingActiveList.splice(elIndex, 1)
      this.editingList = true
      this.modifySheet()
    },
    updateActiveList() {
      // this.$validator.pause()
      this.editingSheet.paragraphs.find(paragraph => paragraph.order === this.activeParagraphOrder).content.find(content => content.order === this.activeContentOrder).content.items = this.editingActiveList
      this.activeParagraphOrder = null
      this.activeContentOrder = null
      this.modifySheet()
    },
    addLink(paragraphOrder) {
      this.activeParagraphOrder = paragraphOrder
      let content = this.editingSheet.paragraphs.find(paragraph => paragraph.order === paragraphOrder).content
      let newContentOrder = 0
      if (content.length > 0) {
        newContentOrder = Math.max.apply(Math, content.map(function (o) {
          return o.order;
        })) + 1
      }
      content.push({
        order: newContentOrder,
        type: 'link',
        content: {
          url: "http://www.example.fr",
          expression: "Nom du site"
        }
      })
      this.modifySheet()
    },
    openLink(url) {
      if (process.env.VUE_APP_USE_TOKEN) {
        // Force using external browser
        window.cordova.InAppBrowser.open(url, '_system')
      } else {
        //Open new tab
        let openUrl = url
        // Check if url starts with http, otherwise the url is stacked to ours
        if (!/^http/.test(url)) {
          openUrl = "http://" + openUrl
        }
        window.open(openUrl, '_blank')
      }
    },
    showEditLinkPrompt(paragraphOrder, contentOrder) {
      this.activeParagraphOrder = paragraphOrder
      this.activeContentOrder = contentOrder

      this.editingActiveLink.expression = this.editingSheet.paragraphs.find(
          paragraph => paragraph.order === paragraphOrder
      ).content.find(
          content => content.order === contentOrder
      ).content.expression

      this.editingActiveLink.url = this.editingSheet.paragraphs.find(
          paragraph => paragraph.order === paragraphOrder
      ).content.find(
          content => content.order === contentOrder
      ).content.url

      this.editActiveLinkPrompt = true
    },
    updateActiveToolLink() {
      // this.$validator.pause()
      this.editingSheet.paragraphs.find(
          paragraph => paragraph.order === this.activeParagraphOrder
      ).content.find(
          content => content.order === this.activeContentOrder
      ).content.expression = this.editingActiveLink.expression

      this.editingSheet.paragraphs.find(
          paragraph => paragraph.order === this.activeParagraphOrder
      ).content.find(
          content => content.order === this.activeContentOrder
      ).content.url = this.editingActiveLink.url

      this.editingActiveLink = {url: '', expression: ''}

      this.modifySheet()
    },

    showDeleteActiveContentPrompt() {
      this.editActiveTextPrompt = false
      this.editActiveToolTipPrompt = false
      this.editActiveListPrompt = false
      this.editActiveLinkPrompt = false
      this.deleteActiveContentPrompt = true
    },
    deleteActiveContent() {
      this.editingSheet.paragraphs.find(
          paragraph => paragraph.order === this.activeParagraphOrder
      ).content = this.editingSheet.paragraphs.find(
          paragraph => paragraph.order === this.activeParagraphOrder
      ).content.filter(content => content.order !== this.activeContentOrder)
      this.activeParagraphOrder = null
      this.activeContentOrder = null
      this.modifySheet()
      this.$emit('reload', this.editingSheet.id)
    },

    cancelModifications() {
      this.sheetModified = false
      this.$emit('cancelModifications', this.editingSheet.id)
    },
    updateHelpSheet() {
      let payload = {
        title: this.editingSheet.title,
        introduction: this.editingSheet.introduction,
        local_authority: this.editingSheet.local_authority,
        paragraphs: [],
        photos: this.editingSheet.photos.length > 0 ? [{image: this.editingSheet.photos[0].image}] : []

      }
      for (let paragraph of this.editingSheet.paragraphs) {
        let payload_paragraph = {
          title: paragraph.title,
          order: paragraph.order,
          texts: [],
          tips: [],
          lists: [],
          links: [],
        }
        for (let item of paragraph.content) {
          switch (item.type) {
            case "text" :
              payload_paragraph.texts.push(
                  {
                    order: item.order,
                    text: item.content.text
                  }
              )
              break
            case "tip":
              payload_paragraph.tips.push(
                  {
                    order: item.order,
                    expression: item.content.expression,
                    description: item.content.description
                  }
              )
              break
            case "list":
              payload_paragraph.lists.push(
                  {
                    order: item.order,
                    items: item.content.items
                  }
              )
              break
            case "link":
              payload_paragraph.links.push(
                  {
                    order: item.order,
                    expression: item.content.expression,
                    url: item.content.url
                  }
              )
              break
            default:
              break
          }
        }
        payload.paragraphs.push(payload_paragraph)
      }
      if (this.editingSheet.newSheet) {
        // payload.photos = this.editingSheet.photos.length > 0 ? [{image: this.editingSheet.photos[0].id}] : []
        this.$store.dispatch("helpSheet/create", payload)
            .then(response => {
              this.editingSheet.newSheet = false
              this.newPhoto = false
              this.sheetModified = false
              this.editingSheet.photos = response.data.photos
              this.editingSheet.id = response.data.id
              this.$emit('reload', this.editingSheet.id)
              this.$emit('cancelModifications')
              window.removeEventListener('beforeunload', null)
              this.$notify({
                color: "primary",
                title: "Fiche créée",
                text: "La fiche a bien été créée et mise à disposition des utilisateurs",
                time: 4000
              })
            })
            .catch(() => {
              this.$notify({
                color: "danger",
                title: "Erreur lors de la création",
                text: "Une erreur est survenue lors de la création de la fiche, veuillez réessayer ultérieurement",
                time: 4000
              })
            })
      } else {
        // payload.photos = this.editingSheet.photos.length > 0 ? [{image: this.editingSheet.photos[0].image}] : []
        payload.id = this.editingSheet.id
        this.$store.dispatch("helpSheet/update", payload)
            .then(() => {
              this.newPhoto = false
              this.sheetModified = false
              this.$emit('reload', this.editingSheet.id)
              this.$emit('cancelModifications')
              window.removeEventListener('beforeunload', null)
              this.$notify({
                color: "primary",
                title: "Fiche mise à jour",
                text: "La fiche a bien été mise à jour et mise à disposition des utilisateurs",
                time: 4000
              })
            })
            .catch(() => {
              this.$notify({
                color: "danger",
                title: "Erreur lors de la mise à jour",
                text: "Une erreur est survenue lors de la mise à jour de la fiche, veuillez réessayer ultérieurement",
                time: 4000
              })
            })
      }
    },
    deleteSheet() {
      this.$store.dispatch("helpSheet/delete", this.editingSheet.id)
          .then(() => {
            this.$emit("deleted", this.editingSheet.id)
          })
    },
    parseTextParagraph(text) {
      const parsedText = text.split('\n')
      const parsedTextLength = new Array(parsedText.length)
      for (let i = 0; i < parsedText.length; i++) {
        parsedTextLength[i] = [parsedText[i], parsedText.length]
      }
      return parsedTextLength
    },
  }
}
</script>

<style lang="scss">
.sheet-card {
  .image {
    transform: translateY(-14px);
    width: calc(100% + 3rem);
    object-fit: contain;
    max-height: 30vh;
  }

  h2 {
    font-size: 3rem;
  }
}
</style>
